import React from "react";

const Modal = ({ toggleModal }) => {
  return (
    <div className="h-screen w-screen top-0 left-0 right-0 bottom-0 fixed ">
      <div
        onClick={() => toggleModal(false)}
        className="h-screen w-screen top-0 left-0 right-0 bottom-0 fixed bg-black opacity-70"
      ></div>
      <div className="absolute bg-white h-[97vh] w-11/12 md:w-4/6 xl:w-1/2 left-1/2 top-1/2 transform translate-x-[-50%] translate-y-[-50%] rounded-xl overflow-auto">
        <div className="w-full h-[4%] flex items-center justify-end ">
          <p
            onClick={() => toggleModal(false)}
            className="mr-3 font-bold text-md md:text-lg xl:text-xl cursor-pointer hover:scale-105"
          >
            X
          </p>
        </div>
        <div className=" mx-2 h-[95%] overflow-auto">
          <div className="bg-bastide mb-4">
            <h1 className="text-white font-bold text-xl lg:text-3xl mx-2 py-1">
              Inscription
            </h1>
          </div>
          <p className="text-sm text-gray-500 mb-6">
            Bastide le Confort Médical - SA au capital de 3.355.875,55 ,85 € -
            12, avenue de la Dame – zone euro 2000 – 30312 Caissargues- RCS
            NIMES 305 635 039.
          </p>
          <div className="bg-bastide mb-6">
            <h1 className="text-white font-semibold text-xl lg:text-3xl mx-2 py-1">
              Charte de protection des données à caractère personnel relative au
              formulaire
            </h1>
          </div>
          <p className="mb-4">
            Les informations recueillies dans le formulaire sont enregistrées
            dans un fichier informatisé par{" "}
            <span className="underline">
              Bastide le Confort Médical, dont le siège social est situé 12
              Avenue de la Dame, 30 132 CAISSARGUES
            </span>{" "}
            (« Responsable du traitement » ou « BASTIDE MEDICAL »).
          </p>
          <p className="mb-3">
            Les données marquées par un astérisque dans le formulaire doivent
            obligatoirement être renseignées afin de pouvoir être contacté par
            BASTIDE MEDICAL.
          </p>
          <p className="mb-3 font-semibold">Base légale des traitements :</p>
          <p className="mb-3 pl-4">- La préparation d’un contrat</p>
          <p className="mb-3 font-semibold">Destinataires des données :</p>
          <p className="mb-3">
            Les données collectées seront communiquées aux seuls destinataires
            suivants :
          </p>
          <p className="mb-3">
            <span className="pl-4">
              - Les services de Bastide le Confort Médical <br />
            </span>
            <span className="pl-4">
              - Les services internes de Bastide Groupe (filiale du Responsable
              de traitement – service informatique)
              <br />
            </span>
            <span className="pl-4">
              - Aux partenaires affiliés dans certains cas
            </span>
          </p>
          <p className="mb-3 font-semibold">
            Durées de conservations des données :
          </p>
          <p className="mb-3">
            Elles sont conservées pendant trois (3) ans à compter de la fin de
            la relation commerciale; trois (3) ans à compter de la collecte par
            le responsable de traitement ou du dernier contact émanant du
            prospect pour un prospect non-client.
          </p>
          <p className="mb-3 font-semibold">Finalités du traitement</p>
          <p className="mb-3">
            • donner suite à la demande de prestation de santé formulée par la
            personne;
          </p>
          <p className="mb-3">
            • la gestion d’opérations techniques de maintenance informatique;
          </p>
          <p className="mb-3">
            • la gestion des demandes de droit d’accès, de rectification et
            d’opposition;
          </p>
          <p className="mb-3 font-semibold">Vos droits</p>
          <p className="mb-3">
            Vous pouvez accéder aux données vous concernant, les rectifier,
            demander leur effacement ou exercer votre droit à la limitation du
            traitement de vos données. Vous pouvez également vous opposer au
            traitement de vos données.
          </p>
          <p className="mb-3">
            Consultez le site cnil.fr pour plus d’informations sur vos droits.
          </p>
          <p className="mb-3">
            Pour exercer ces droits ou pour toute question sur le traitement de
            vos données dans ce dispositif, vous pouvez contacter :
            dpo@bastide-medical.fr - DPO – Bastide le confort médical – DPO – CS
            28219 – 30942 NIMES CEDEX 9 .
          </p>
          <p className="mb-3">
            Si vous estimez, après nous avoir contactés, que vos droits «
            Informatique et Libertés » ne sont pas respectés, vous pouvez
            adresser une réclamation à la CNIL.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Modal;
