import React, { useEffect, useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import logoBastide from "./assets/bcm.png";
import logoHapni from "./assets/hpn.png";
import Modal from "./components/Modal";
import Popup from "./components/Popup";
import { Icon } from "@iconify/react";

function App() {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    address: "",
    zipCode: "",
    city: "",
    mail: "",
    medecin: "",
    phoneNumber: "",
    conditions: false,
    captcha: null,
  });
  const [errors, setErrors] = useState({});
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [displayModal, setDisplayModal] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [displaySuccessPopup, setDisplaySuccessPopup] = useState(false);
  const captchaRef = useRef(null);

  const isButtonHidden =
    Object.values(errors).some((error) => error !== "") || !formData.captcha;

  const handleResize = () => setScreenSize(window.innerWidth);

  const toggleModal = () => {
    setDisplayModal(!displayModal);
    console.log(formData);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;

    setFormData((prev) => ({
      ...prev,
      [name]: val,
    }));

    setErrors((prev) => ({
      ...prev,
      [name]: validateField(name, val),
    }));
  };

  const validateField = (name, value) => {
    switch (name) {
      case "firstname":
      case "lastname":
        return value.length > 100
          ? `${name} ne doit pas dépasser 100 caractères`
          : "";
      case "address":
        return value.length > 150
          ? "L'adresse ne doit pas dépasser 150 caractères"
          : "";
      case "zipCode":
        return value.length !== 5
          ? "Le code postal doit faire 5 caractères."
          : "";
      case "phoneNumber":
        return value.length !== 10
          ? "Le numéro de téléphone doit faire 10 caractères."
          : "";
      case "mail":
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return !value.match(mailformat) ? "Format de mail incorrect." : "";
      case "conditions":
        return !value ? "Les conditions doivent être approuvées" : "";
      default:
        return "";
    }
  };

  const handleCaptchaChange = (value) =>
    setFormData((prev) => ({ ...prev, captcha: value }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.captcha) {
      setErrors((prev) => ({
        ...prev,
        captcha: "Veuillez vérifier le captcha",
      }));
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/hapni`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setIsCreate(true);
        setDisplaySuccessPopup(true);
        setTimeout(() => {
          setIsCreate(false);
        }, 3000);

        setTimeout(() => {
          setDisplaySuccessPopup(false);
        }, 3900);

        setFormData({
          firstname: "",
          lastname: "",
          address: "",
          zipCode: "",
          city: "",
          mail: "",
          medecin: "",
          phoneNumber: "",
          conditions: false,
          captcha: null,
        });
        captchaRef.current.reset();
      } else {
        setErrors((prev) => ({
          ...prev,
          captcha:
            data.message === "Captcha verification failed"
              ? "Captcha invalide"
              : "",
        }));
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de la demande:", error);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className={`flex flex-col h-screen`}>
        <header className="flex items-center justify-between h-16 lg:h-[72px] lg:py-2 p-2 lg:px-11 px-4 shadow-md">
          <div className="w-1/2 flex">
            <img className="lg:w-28 w-24" src={logoHapni} alt="Logo Hapni" />
          </div>
          <div className=" w-1/2 flex justify-end">
            <img
              className=" lg:w-28 lg:h-14 w-24 h-12"
              src={logoBastide}
              alt="Logo Bastide"
            />
          </div>
        </header>

        <main className="w-full flex justify-center h-[calc(100vh-64px)] lg:h-[calc(100vh-72px)]">
          <div className="flex flex-col items-center lg:w-2/4 lg:mt-0">
            <h1 className="text-lg lg:text-2xl font-semibold text-center">
              Finalisez votre demande de prestation
            </h1>
            <div className="flex flex-col items-center lg:w-2/4 lg:mt-0 h-full">
              <form
                className="mt-1 mx-4 h-full flex flex-col justify-between"
                id="form"
                onSubmit={handleSubmit}
              >
                <div className="flex justify-between">
                  <div className="w-1/2 pr-1">
                    <label className="block text-sm font-medium text-gray-700">
                      Prénom
                      <abbr style={{ color: "red" }}> *</abbr>
                    </label>
                    <input
                      required
                      id="firstname"
                      type="text"
                      name="firstname"
                      placeholder="Prénom"
                      value={formData.firstname}
                      onChange={handleChange}
                      className="mt-1 p-2 w-full border rounded-md"
                    />
                    {errors.firstname && (
                      <span className="text-red-500 text-sm">
                        {errors.firstname}
                      </span>
                    )}
                  </div>
                  <div className="w-1/2 pl-1">
                    <label className="block text-sm font-medium text-gray-700">
                      Nom
                      <abbr style={{ color: "red" }}> *</abbr>
                    </label>
                    <input
                      required
                      id="lastname"
                      type="text"
                      name="lastname"
                      placeholder="Nom"
                      value={formData.lastname}
                      onChange={handleChange}
                      className="mt-1 p-2 w-full border rounded-md"
                    />
                    {errors.lastname && (
                      <span className="text-red-500 text-sm">
                        {errors.lastname}
                      </span>
                    )}
                  </div>
                </div>

                <div className="">
                  <label className="block lg:text-sm font-medium text-gray-700">
                    Numéro de téléphone
                    <abbr style={{ color: "red" }}> *</abbr>
                  </label>
                  <input
                    required
                    id="phoneNumber"
                    type="number"
                    name="phoneNumber"
                    placeholder="Numéro de téléphone"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="mt-1 p-1 lg:mt-1 lg:p-2 w-full border rounded-md"
                  />
                  {errors.phoneNumber && (
                    <span className="text-red-500 text-sm">
                      {errors.phoneNumber}
                    </span>
                  )}
                </div>

                <div className="">
                  <label className="block lg:text-sm font-medium text-gray-700">
                    Adresse
                  </label>
                  <input
                    id="address"
                    type="text"
                    name="address"
                    placeholder="Numéro, rue"
                    value={formData.address}
                    onChange={handleChange}
                    className="mt-1 p-1 lg:mt-1 lg:p-2 w-full border rounded-md"
                  />
                  {errors.address && (
                    <span className="text-red-500 text-sm">
                      {errors.address}
                    </span>
                  )}
                </div>

                <div className="flex justify-between">
                  <div className="w-1/2 pr-1">
                    <label className="block lg:text-sm font-medium text-gray-700">
                      Code postal
                      <abbr style={{ color: "red" }}> *</abbr>
                    </label>
                    <input
                      required
                      id="zipCode"
                      type="number"
                      name="zipCode"
                      placeholder="Code postal"
                      value={formData.zipCode}
                      onChange={handleChange}
                      className="mt-1 p-1 lg:mt-1 lg:p-2 w-full border rounded-md"
                    />
                    {errors.zipCode && (
                      <span className="text-red-500 text-sm">
                        {errors.zipCode}
                      </span>
                    )}
                  </div>
                  <div className="w-1/2 pl-1">
                    <label className="block lg:text-sm font-medium text-gray-700">
                      Ville
                    </label>
                    <input
                      id="city"
                      type="text"
                      name="city"
                      placeholder="Ville"
                      value={formData.city}
                      onChange={handleChange}
                      className="mt-1 p-1 lg:mt-1 lg:p-2 w-full border rounded-md"
                    />
                  </div>
                </div>

                <div className="">
                  <label className="block lg:text-sm font-medium text-gray-700">
                    Mail
                  </label>
                  <input
                    id="mail"
                    type="email"
                    name="mail"
                    placeholder="Mail"
                    value={formData.mail}
                    onChange={handleChange}
                    onBlur={handleChange}
                    className="mt-1 p-1 lg:mt-1 lg:p-2 w-full border rounded-md"
                  />
                  {errors.mail && (
                    <span className="text-red-500 text-sm">{errors.mail}</span>
                  )}
                </div>

                <div className="">
                  <label className="block lg:text-sm font-medium text-gray-700">
                    Médecin prescripteur
                  </label>
                  <input
                    id="medecin"
                    type="texte"
                    name="medecin"
                    placeholder="Médecin prescripteur"
                    value={formData.medecin}
                    onChange={handleChange}
                    onBlur={handleChange}
                    className="mt-1 p-1 lg:mt-1 lg:p-2 w-full border rounded-md"
                  />
                </div>

                <div className="flex flex-col items-center">
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={process.env.REACT_APP_CAPKEY}
                    onChange={handleCaptchaChange}
                  />
                  {errors.captcha && (
                    <span className="text-red-500 text-sm">
                      {errors.captcha}
                    </span>
                  )}
                </div>

                <div className="">
                  <label className="block lg:text-sm font-medium text-gray-700">
                    Conditions d'utilisation
                    <abbr style={{ color: "red" }}> *</abbr>
                  </label>
                  <input
                    type="checkbox"
                    required
                    id="conditions"
                    name="conditions"
                    checked={formData.conditions}
                    onChange={handleChange}
                  />{" "}
                  <label>
                    En cliquant sur ce bouton, vous acceptez{" "}
                    <span
                      onClick={toggleModal}
                      className="underline text-blue-500 cursor-pointer"
                    >
                      les conditions d'utilisation
                    </span>
                  </label>
                  <br />
                  {errors.conditions && (
                    <span className="text-red-500 text-sm">
                      {errors.conditions}
                    </span>
                  )}
                </div>

                <div className="flex justify-center xl:mb-4 mb-1">
                  <button
                    disabled={isButtonHidden}
                    type="submit"
                    className={`lg:w-3/5 lg:py-1 px-2 py-1 text-base font-semibold  rounded-lg  ${
                      isButtonHidden
                        ? "text-white bg-gray-300 hover:bg-gray-300 cursor-default"
                        : "cursor-pointer border-2 text-white bg-orange-500 border-orange-500 hover:bg-white hover:border-2 hover:text-orange-500"
                    }`}
                  >
                    Faire la demande
                  </button>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
      {displayModal && <Modal toggleModal={setDisplayModal} />}
      {displaySuccessPopup && <Popup isCreate={isCreate} />}
    </>
  );
}

export default App;
