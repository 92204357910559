import React from "react";
import { Icon } from "@iconify/react";

const Popup = ({ isCreate }) => {
  return (
    <div className="fixed w-screen bottom-0 left-0">
      <div
        id="popup"
        className={`mx-auto font-semibold text-white flex justify-center items-center rounded-lg w-96 h-14 mb-10 bg-green-700 bg-opacity-90 shadow-[0_10px_20px_rgba(0,_175,_90,_0.6)] ${
          isCreate ? "appear" : "desappear"
        }`}
      >
        <Icon
          icon="material-symbols:check-circle"
          color="white"
          width="25"
          height="25"
          className="mx-2"
        />
        <p>Demande envoyé avec succès</p>
      </div>
    </div>
  );
};

export default Popup;
